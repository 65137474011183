
import React from "react";

const Home = (props) => {
  return (
    <div></div>
  );
}

export default Home;
